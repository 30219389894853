import(/* webpackMode: "eager", webpackExports: ["WebVitals"] */ "/app/app/components/web-vitals.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LiveStream"] */ "/app/app/live-steam-banner/live-stream.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/products/[filename]/products-preview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MegaMenuWrapper"] */ "/app/components/server/MegaMenuWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SocialIcons"] */ "/app/components/socialIcons/socialIcons.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppInsightsProvider"] */ "/app/context/app-insight-client.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.15_next@14.2.15_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwri_jgi2u3ytsohljmkd3hkqlmz7ee/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.15_next@14.2.15_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwri_jgi2u3ytsohljmkd3hkqlmz7ee/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.15_next@14.2.15_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwri_jgi2u3ytsohljmkd3hkqlmz7ee/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+test@1.47.2_react-dom@18_jxi55mcbetwp3hvwe6v555v7m4/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+test@1.47.2_react-dom@18_jxi55mcbetwp3hvwe6v555v7m4/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+test@1.47.2_react-dom@18_jxi55mcbetwp3hvwe6v555v7m4/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+test@1.47.2_react-dom@18_jxi55mcbetwp3hvwe6v555v7m4/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+test@1.47.2_react-dom@18_jxi55mcbetwp3hvwe6v555v7m4/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
